$(document).ready(() => {
    Alerts.load();
    Dashboard.load();
});

const Alerts = (() => {
    const confirm = (text, confirm_text, cancel_text) => {
        return swal.fire({
            title: "",
            text: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: confirm_text,
            cancelButtonText: cancel_text,
            position: "top"
        }).then(result => result.value === true);
    };

    const load_confirm = () => {
        $(document).on("click", "[data-confirm]", function(event) {
            event.preventDefault();
            const $this = $(this);

            confirm($this.data("confirm"), $this.data("confirm_text"), $this.data("cancel_text")).then(confirmation => {
                if(confirmation) {
                    if ($this.attr("href") != undefined) {
                        window.location.href = $this.attr("href");
                    } else {
                        $this.closest("form").submit();
                    }
                }
            });
        });
    };

    return {
        load: () => {
            load_confirm();
        },
        confirm: (text, confirm_text = "", cancel_text = "") => confirm(text, confirm_text, cancel_text)
    };
})();

const Dashboard = (() => {
    const open_menu = () => {
        $(".open_menu-js").on("click", function() {
            $(".navigation-js").addClass('show');
        });
    };

    const close_menu = () => {
        $(".close_menu-js").on("click", function() {
            $(".navigation-js").removeClass('show');
        });
    };

    return {
        load: () => {
            open_menu();
            close_menu();
        }
    };
})();
